@import 'custom-theme.scss';
@import '@fortawesome/fontawesome-free/css/all.css';


@mixin font-face($family, $src, $style: normal, $weight: normal) {
  @font-face {
    font-family: $family;
    src: url("#{$src}.eot");

    src: url("#{$src}.eot?#iefix") format("embedded-opentype"),
      url("#{$src}.woff") format("woff"),
      url("#{$src}.ttf") format("truetype"),
      url("#{$src}.svg##{$family}") format("svg");

    font-style: $style;
    font-weight: $weight;
  }
}
@include font-face("Proxima Nova ", "../assets/font/proximanovasoft-regular-webfont");

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/font/proximanovasoft-regular-webfont.eot'); /* IE9 Compat Modes */
  src: url('../assets/font/proximanovasoft-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/font/proximanovasoft-regular-webfont.woff') format('woff'), /* Modern Browsers */
       url('../assets/font/proximanovasoft-regular-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/font/proximanovasoft-regular-webfont.svg#../assets/font/proximanovasoft-regular-webfont') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

.custom-mat-form-field {
  div {
    border-radius:0 ;
   }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch{
    border-right-width: 0;
  }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline .mdc-notched-outline__notch{
    border-right-width: 0;
  }
}

.border-custom {
    border: 1px solid #b9b9b9;
}


.g-skeleton {
  border-radius: 10px;
  background-color: rgb(224, 224, 224);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

.g-image-height {
  height: 200px;
  width: 200px;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    border-radius: 2px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    border-radius: 10px;
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    border-radius: 2px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.image-wrapper {
  display: flex;
  gap: 16px;
}
