@use '@angular/material' as mat;
@include mat.core();
@tailwind base;
@tailwind components;
@tailwind utilities;


$custom-palette-primary: (
  50: #e3e7f1,  // Lightest shade (10% lighter)
  100: #c7d2e3,  // Lighter shade (5% lighter)
  200: #a9bdd7,  // Base color
  300: #8ca8c9,  // Slightly darker shade (5% darker)
  400: #7083b0,  // Darker shade (10% darker)
  500: #50737C,  // Provided color
  600: #396469,  // Even darker shade (10% darker)
  700: #22555c,  // Darkest shade (20% darker)
  800: #0c464b,  // Very dark shade (30% darker)
  900: #00373c,  // Almost black (40% darker)
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: rgba(white, 0.87),
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

$custom-palette-accent: (
  50: #d8dee4, // Lightest shade (10% lighter)
  100: #c0c9d3, // Lighter shade (5% lighter)
  200: #a8b3be, // Base color
  300: #909e9d, // Slightly darker shade (5% darker)
  400: #78888b, // Darker shade (10% darker)
  500: #3a3c46, // Provided color
  600: #1e2028, // Even darker shade (10% darker)
  700: #000000, // Darkest shade (20% darker - black)
  800: #000000, // Very dark shade (30% darker - black)
  900: #000000, // Almost black (40% darker - black)
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: white,
    300: white,
    400: white,
    500: rgba(white, 0.87),
    600: white,
    700: white,
    800: white,
    900: white,
  )
);
$custom-primary: mat.define-palette($custom-palette-primary);
$custom-accent: mat.define-palette($custom-palette-accent);



$custom-warn: mat.define-palette(mat.$red-palette);

$custom-theme: mat.define-light-theme((
  color: (
    primary: $custom-primary,
    accent: $custom-accent,
    warn: $custom-warn,
  )
));

@include mat.all-component-themes($custom-theme);

.dense-1 {
  @include mat.all-component-densities(-1);
}

.dense-2 {
  @include mat.all-component-densities(-2);
}

