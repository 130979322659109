html, body { height: 100%; }
body { margin: 0;  font-family: "Proxima Nova", Helvetica, Arial, sans-serif !important; }

.custom-border {
  border: 0 none;
  tbody tr {
    border-left: 1px solid #CACACA;
    border-right: 1px solid #CACACA;
    &:last-child {
      border-bottom: 1px solid #CACACA;
    }
  }
}
